import React, { useState, useEffect, createRef } from 'react';
import Axios from 'axios';
import Slider from 'react-slick';

import { LinkModal } from "kh-common-components";
import { modalContents } from "../../constants/modal-content";
import flowmoji50 from 'images/rac/natural-gas/50-flowmoji.svg';

import 'styles/components/cards/rac-plans.css';


function RacPlans(props) {

    const [plansData, setPlansData] = useState(null);

    const racOfferPromo = props.racOfferPromo;
    const racOfferPromoTerms = props.racOfferPromoTerms;
    const racOfferPromoDisc = parseInt(process.env.RAC_OFFER_PROMO_DISC);

    const carouselClass = 'mer-media-card-carousel mer-media-card-carousel-react container-fluid-constrained mer-slick-slider--height-equal';

    let mediaCardRef = [];

    const getPlanData = async () => {

        if (plansData !== null) {
            return;
        }

        try {
            const plans = await Axios.get(process.env.PRODUCTS_API);
            const reversed = plans.data.reverse();
            setPlansData(reversed);
        } catch (error) {
            console.log('Error getting plan details');
        }
    };

    const settings = {
        // rows: 0, // causes memory leak
        // lazyLoad: true,
        arrows: false,
        dots: true,
        infinite: false,
        swipeToSlide: true,
        draggable: true,
        mobileFirst: true,
        transformEnabled: false,
        useTransform: false,

        speed: 800,
        slidesToShow: 2,

        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '200px',

        dotsClass: 'd-flex slick-dots',
        className: carouselClass,

        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '200px'
                }
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '200px'
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '200px'
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '200px'
                }
            },
                {
                breakpoint: 860,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '150px'
                }
            },
            {
                breakpoint: 639, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 550, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '30px'
                }
            }
        ]

    };

    useEffect(() => {
        getPlanData();

        mediaCardRef.forEach(referenceItem => {
            if(referenceItem.current) {
                referenceItem.current.parentElement.classList.add('col', 'mer-card', 'mer-card-media', 'mdc-card');
            }
        });

    }, [plansData]);

    return(
        <>
            { Array.isArray(plansData) && plansData.length > 0 &&
                <section id="plans" 
                        className="mer-theme--light mer-bg--ui-light mer-py-ju">
                    <div className="container">

                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-8">
                                <div className="content text-center">
                                    <h2 className="mt-2 mer-typography--headline1 mer-color--primary-cyan">Natural gas plans and pricing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Slider {...settings}>

                        {plansData.filter(plan => plan.key !== '2').map((plan, index) => {
                            const newRef = createRef();
                            mediaCardRef.push(newRef);

                            const creditOfferText = plan.isStandard ? 'Get a $50 bonus when you choose this plan.' : 'For a limited time, get a bonus $50 account credit';

                            const planOfferText = plan.isStandard ? 'Our standard plan' : 'Our limited time offer';
                            const planOfferClass = plan.isStandard ? 'standard' : 'limited';

                            return (
                                <div className="mer-card-media-react__container mer-plan-card mer-card mdc-card text-center py-4" key={index} ref={newRef}>
                                    <h6 className={`col-6 mx-auto plan-offer ${planOfferClass} py-1`}>
                                        <strong>{planOfferText}</strong>
                                    </h6>
                                    <h4 className="mer-plan-card__title">{plan.name}</h4>
                                    <p className="mer-plan-card__description">
                                        <span className="mer-plan-card__discount">
                                            {plan.isStandard ? parseInt(plan.onTimePaymentDiscount) + racOfferPromoDisc : parseInt(plan.discount) + racOfferPromoDisc}
                                            <span className="mer-plan-card__discount-percent">{plan.isStandard ? ('.' + parseFloat(plan.onTimePaymentDiscount).toFixed(1).split('.')[1]) : ''}%</span>
                                        </span>
                                        <span className="mer-plan-card__discount-description">
                                            {plan.isStandard ? 'off gas usage charges when you pay on time' : 'off gas usage charges for at least 12 months'}
                                        </span>
                                    </p>
                                    <ul className="mer-list mer-color--primary-navy mer-border--bottom mer-border--top mer-border--color-gs-lighter m-0 px-2 py-3 mb-3">
                                        <li><strong>{plan.isStandard ? 'Choice of payment method' : 'Direct debit'}</strong></li>
                                        <li><strong>{plan.isStandard ? 'Quarterly bills' : 'Easy monthly instalments'}</strong></li>
                                        <li><strong>{plan.isStandard ? 'Email or paper bills' : 'Email bills'}</strong></li>
                                        <li>No setup or exit costs</li>
                                        <li>No lock-in contracts</li>
                                    </ul>
                                    <h5 className="col-5 mx-auto pt-0 my-0 mer-my-sm"><span className="mer-color--primary-blue">PLUS</span></h5>
                                    <div className="col-4 col-lg-3 mx-auto my-n4 py-0 mer-my-sm">
                                        <div className="mer-panel__images d-flex align-items-center align-self-center justify-content-center">
                                            <img
                                                className="mer-panel__image"
                                                src={flowmoji50}
                                                alt="50 dollars account credit"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-7 mx-auto py-0 mer-my-sm">
                                        <p>{creditOfferText}</p>
                                    </div>
                                    <div className="content mx-4 text-center">
                                        <a href={plan.key === '537' ? `/sign-up/promo/${racOfferPromo}` : `/sign-up/product/${plan.key}`}
                                            className="mer-button mer-button--primary mer-button--size-full-width mb-3">
                                            <span className="mer-color--gs-white">Sign up</span>
                                        </a>
                                        <small className="ng-plan-details text-center mer-typography--body3">
                                            <LinkModal
                                                modalContents={modalContents}
                                                useModal="true"
                                                title={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                displayContent={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                modalTitle={plan.isStandard ? 'View plan details and standard terms' : 'View plan details and non-standard terms'}
                                                modalContentName={plan.isStandard ? `plan_details_and_terms_${plan.key}` : racOfferPromoTerms}
                                                okayButtonLabel="Okay"
                                            />
                                        </small>
                                    </div>
                                </div>
                        )})}

                    </Slider>

                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                                <div className="text-center mer-mt-sm">
                                    <p className="mb-2 pb-0">
                                        <small>
                                            <LinkModal
                                                modalContents={modalContents}
                                                useModal="true"
                                                displayContent="View prices and fees for all natural gas customers"
                                                title="View prices and fees for all natural gas customers"
                                                modalTitle="View prices and fees for all natural gas customers"
                                                modalContentName="standard_prices_and_fees"
                                                okayButtonLabel="Close"
                                            />
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
);
}

export default RacPlans;