import React from 'react';
import Image from 'components/common/Image';
import LayoutPanel from 'components/layout/layoutPanel';

import canstar from 'images/rac/natural-gas/cns-msc-wa-natural-gas-suppliers-2023-consec.png';


function RacNgProductHighlight (props) {

    return (
        <LayoutPanel background={props.panelBackground}
                     padding={props.panelPadding}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 content text-center">
                        <h2>Why choose Kleenheat?</h2>
                    </div>
                </div>

                <div className="row justify-content-lg-between justify-content-center mer-text--size-lg">
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/icons/flowmoji/flowmoji-wa.svg"
                                    alt="Flowmoji Western Australia"
                                />
                            </div>
                            <div className="col-6 col-sm-4 col-lg-10 text-lg-center">
                                <h5 className="my-0">WA born and bred</h5>
                                <p>We've been at home in WA for <span className="mer-text--no-wrap">over 65 years.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6">
                                <Image className="mer-panel__image"
                                    src="/flybuys-points-1.png"
                                    alt="Flybuys 1 point per $1 spent"
                                />
                            </div>
                            <div className="col-6 col-sm-4 col-lg-10 text-lg-center">
                                <h5 className="my-0">Ongoing value</h5>
                                <p>Collect 1 Flybuys point per $1 spent on natural gas.<sup>~</sup></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="row justify-content-center">
                            <div className="col-4 col-sm-2 col-lg-6 mer-panel__images">
                                <img src={canstar} 
	                                 className="mer-panel__image"
	                                 alt="2023 Most satisfied customers WA natural gas supplier award" />
                            </div>
                            <div className="col-6 col-sm-4 col-lg-10 text-lg-center">
                                <h5 className="my-0">Award-winning service</h5>
                                <p>We always put our <span className="mer-text--no-wrap">customers first.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    );
}

export default RacNgProductHighlight;